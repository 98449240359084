<template>
  <b-container fluid>
    <bo-page-title />

    <b-card>
      <b-row>
        <b-col md=4 v-for="(task, index) in data" :key="index">
          <b-card class="category-card border">
            <b-card-header class="d-flex justify-content-between">
              <p class="c-pointer" @click="setCategoryUpdate(task)" v-if="!task.onInput"><strong>{{task.mtc_name}}</strong></p>
              <b-form-group v-else-if="task.onInput == true && moduleRole('categoryUpdate')">
                <b-input-group>
                  <b-form-input
                    v-model="task.mtc_name_temp"
                    @keyup.enter="updateCategory(task, 'Category'+index)"
                    @keyup.esc="task.onInput = false"
                    placeholder="cth: Memanaskan Mobil" />
                    <b-input-group-append>
                      <b-button v-b-tooltip.hover.noninteractive="'Save (Enter)'" variant="success" @click="updateCategory(task, 'Category'+index)">
                        <i class="ti-check"></i>
                      </b-button>
                      <b-button variant="danger" @click="task.onInput = false" v-b-tooltip.hover.noninteractive="'Cancel (Esc)'">
                        <i class="ti-close"></i>
                      </b-button>
                    </b-input-group-append>
                </b-input-group>
              <VValidate :ref="'Category'+index" :keyName="'Category'+index" name="Category" v-model="task.mtc_name_temp" rules="required" />
              </b-form-group>
              <div>
                <b-button
                  v-b-tooltip.hover
                  v-if="!task.onInput && moduleRole('itemAdd')"
                  title="Add Task"
                  class="btn-icon mr-2"
                  variant="outline-info"
                  size="sm"
                  @click="addTask(task)"
                >
                  <i class="ti-plus"></i>
                </b-button>
                <b-button 
                  variant="danger"
                  href="javascript:;"
                  v-if="moduleRole('categoryDelete')"
                  v-b-tooltip.hover.noninteractive="'Remove Category'" 
                  @click="removeCategory(task)"
                  class="btn-icon">
                  <i class="ti-trash"></i>
                </b-button>
              </div>
            </b-card-header>
            <perfect-scrollbar class="list-item">
              <ValidationObserver :ref="'VFormTask'+index">
                <template v-for="(item, k2) in task.task">
                  <div v-if="!item.onInput" :key="`${index}${k2}`">
                    <li @click.self="setOnInputTrue(item)" class="w-100 d-flex justify-content-between">
                      {{ item.mti_title }}
                      <b-button 
                        variant="outline-danger"
                        href="javascript:;"
                        v-b-tooltip.hover.noninteractive="'Remove'" 
                        v-if="moduleRole('itemDelete')"
                        @click="removeTask(item)"
                        class="btn-icon">
                        <i class="ti-trash"></i>
                      </b-button>
                    </li>
                  </div>
                  <b-form-group :key="`${index}${k2}`" v-else label-for="inputCategory">
                      <b-input-group>
                        <b-form-input
                          @keyup.enter="submitTask(item, task.mtc_id, 'Task'+k2)"
                          @keyup.esc="cancelEdit(item, task)"
                          v-model="item.mti_title_temp"
                          placeholder="cth: Memanaskan Mobil" />
                          <b-input-group-append>
                            <b-button v-b-tooltip.hover.noninteractive="'Save (Enter)'" variant="success" @click="submitTask(item, task.mtc_id, 'Task'+k2)">
                              <i class="ti-check"></i>
                            </b-button>
                            <b-button variant="danger" @click="cancelEdit(item, task)" v-b-tooltip.hover.noninteractive="'Cancel (Esc)'">
                              <i class="ti-close"></i>
                            </b-button>
                          </b-input-group-append>
                      </b-input-group>
                    <VValidate :ref="'Task'+k2" :keyName="'Task'+k2" name="Task" v-model="item.mti_title_temp" rules="required" />
                  </b-form-group>
                </template>
              </ValidationObserver>
            </perfect-scrollbar>
          </b-card>
        </b-col>
        <b-col md=4 class="align-self-center text-center" v-if="moduleRole('categoryAdd')">
          <div class="card-add-category">
            <b-button @click="newCategory" v-b-tooltip.hover.noninteractive="'Add Category'" variant="outline-primary" class="circle mb-3" size="lg">
              <i class="fas fa-plus text-blue"></i>
            </b-button>
            <p>Add Category</p>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-modal 
      id="newCategory"
      title="Add New Category"
      hide-footer
      :no-enforce-focus="true"
      centered
      >
        <ValidationObserver ref="VFormCategory" v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(submitCategory)">
            <b-form-group label-for="inputCategory">
              <template #label>Category <strong class="text-danger">*</strong></template>
              <b-form-input id="inputCategory"
                v-model="dataModal.mtc_name"
                placeholder="cth: Storage" />
              <VValidate name="Category" v-model="dataModal.mtc_name" rules="required" />
            </b-form-group>
            <b-row class="justify-content-end">
              <b-col md='auto'>
                <b-button type="button" variant="transparent" @click="doCloseModal('newCategory')">Cancel</b-button>
                <b-button type="submit" variant="success btn-rounded">Submit</b-button>
              </b-col>
            </b-row> 
          </b-form>
        </ValidationObserver>
    </b-modal>
  </b-container>
</template>
<style scoped>
.c-pointer {
  cursor: pointer;
}
.category-card .list-item {
  max-height: 450px;
  overflow-y: auto;
  padding-right: 20px;
}
.card.category-card li {
    list-style: none;
    border: 1px solid #c7c7c7;
    margin-bottom: 10px;
    padding: 8px 16px;
    cursor: pointer;
}
</style>
<script>
	import GlobalVue from '@/libs/Global.vue'
  import Gen from '@/libs/Gen.js'
  
  export default {
		extends: GlobalVue,
    data() {
      return {
        idKey: 'tbt_id',
        statusKey: 'tbt_status',
        mrValidation: {},
        dataModal: {}
      }
    },
    mounted(){
      this.setFilter()
      this.apiGet()
    },
    methods: {
      setCategoryUpdate(task){
        if(this.moduleRole('categoryUpdate')){
          task.onInput = true
        }
      },
      removeTask(task){
        this.$swal({
          title: 'Are you sure want to delete this task?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: "No, Cancel",
          confirmButtonText: "Yes, Sure"
        }).then(result => {
          if(result.isConfirmed){
            Gen.apiRest('/do/' + this.modulePage, { data: { id: task.mti_id, type: 'remove-task' } }, "POST").then(
              () => {
                this.apiGet()
              }).catch(err=> {
                this.loadingOverlay = false 
                if(err){
                    err.statusType = err.status
                    err.status = "error"
                    err.message = err.response.data.message
                    err.title = err.response.data.title
                    err.messageError = err.message
                }
                this.doSetAlertForm(err)
              })
          }
        })
      },
      removeCategory(category){
        this.$swal({
          title: 'Are you sure want to delete this category?',
          text: 'All tasks in this category will be deleted too',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: "No, Cancel",
          confirmButtonText: "Yes, Sure"
        }).then(result => {
          if(result.isConfirmed){
            Gen.apiRest('/do/' + this.modulePage, { data: { id: category.mtc_id, type: 'remove-category' } }, "POST").then(
              res => {
                this.$swal({
                  title: res.data.message,
                  icon: 'success',
                }).then(() => {
                  this.apiGet()
                })
              }).catch(err=> {
                this.loadingOverlay = false 
                if(err){
                    err.statusType = err.status
                    err.status = "error"
                    err.message = err.response.data.message
                    err.title = err.response.data.title
                    err.messageError = err.message
                }
                this.doSetAlertForm(err)
              })
          }
        })
      },
      cancelEdit(item, task){
        if(item.new){
          const filtered = task.task.filter(v => !v.id || v.id != item.id)
          this.$set(task, 'task', filtered)
        }else{
          item.onInput = false
        }
      },
      setOnInputTrue(task){
        if(this.moduleRole('itemUpdate')){
          task.onInput = true
          task.mti_title_temp = task.mti_title
        }
      },
      submitTask(item, idCat, ref){
        this.$refs[ref][0].validate().then(success => {
          console.log(success, 'success')
          if(!success.valid) {
            return success
          }
          Gen.apiRest('/do/' + this.modulePage, { data: Object.assign({ idCat, type: 'add-task', isAdd: !!item.new }, {...item}) }, "POST").then(
            res => {
            item.mti_title = item.mti_title_temp
            item.onInput = false
            item.mti_id = res.data.id
          }).catch(err=> {
            this.loadingOverlay = false 
            if(err){
                err.statusType = err.status
                err.status = "error"
                err.message = err.response.data.message
                err.title = err.response.data.title
                err.messageError = err.message
            }
            this.doSetAlertForm(err)
          })
        })
      },
      updateCategory(item, ref){
        this.$refs[ref][0].validate().then(success => {
          if(!success.valid) {
            return success
          }
          Gen.apiRest('/do/' + this.modulePage, { data: Object.assign({type: 'update-category' }, {...item}) }, "POST").then(
            res => {
            item.mtc_name = item.mtc_name_temp
            item.onInput = false
            item.mtc_id = res.data.id
          }).catch(err=> {
            this.loadingOverlay = false 
            if(err){
                err.statusType = err.status
                err.status = "error"
                err.message = err.response.data.message
                err.title = err.response.data.title
                err.messageError = err.message
            }
            this.doSetAlertForm(err)
          })
        })
      },
      addTask(data){
        data.task.unshift({ new: true, mti_title_temp: '', mti_title: '', onInput: true, id: Math.random() * 10})
      },
      newCategory(){
        this.$set(this, 'dataModal', {})
        this.$bvModal.show('newCategory')
      },
      submitCategory(){
        this.$refs.VFormCategory.validate().then(success => {
          if(!success) return
          this.$swal({
            title: 'Loading...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            }
          })
          return this.doSubmit('/do/' + this.modulePage, Object.assign({ type: 'new-category'}, {...this.dataModal}), (type, resp) => {
            if(type == 'success'){
              this.$swal({
              title: resp.message,
              icon: 'success',
              confirmButtonText: 'Oke'
            }).then(result => {
                if(result.value){
                  this.$bvModal.hide('newCategory')
                  this.$set(this, 'dataModal', {})
                  this.apiGet()
                }
              })
            }else{
              if(resp.response.status==400){
                return this.$swal({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
              else {
                return this.$swal({
                  title: 'Terjadi suatu kesalahan, silakan coba lagi nanti.',
                  text: 'Mohon periksa kembali data anda',
                  icon: 'error',
                })
              } 
            }
          }, "POST", "VFormCategory")
        })
      },
      setFilter(){
        this.filter.status = this.$route.query.status || 'pending'
        this.filter.vehicle = this.$route.query.vehicle || 'all'
        this.filter.search = this.$route.query.search || ''
      },
      doCloseModal(id){
        this.dataModal = {}
        this.$bvModal.hide(id)
      },
      doReject(row){
        this.$set(this, 'dataModal', row)
        this.$bvModal.show('rejectModal')
      },
    },
    watch:{
      $route(){
        this.setFilter()
        this.apiGet()
      },
    }
  }
</script>